import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import ServiceDetailsContent from '../../components/ServiceDetails/ServiceDetailsContent'
 
const Details = () => {

    const technologies = [
        {
            id: 0,
            className: 'react',
            technology: 'React',
        },
        {
            id: 1,
            className: 'javascript.js',
            technology: 'Javascript.js',
        },
        {
            id: 2,
            className: 'Typescript.js',
            technology: 'TypeScript.js',
        },
        {
            id: 3,
            className: 'next.js',
            technology: 'Next.js',
        },
        {
            id: 4,
            className: 'node.js',
            technology: 'Node.js',
        },
        {
            id: 5,
            className: 'Express.js',
            technology: 'Express.js',
        },
        {
            id: 6,
            className: 'react',
            technology: 'React',
        },
        {
            id: 7,
            className: 'python',
            technology: 'Python',
        },
        {
            id: 8,
            className: 'GraphQL',
            technology: 'GraphQL',
        },
        {
            id: 9,
            className: 'java',
            technology: 'Java',
        },
        {
            id: 10,
            className: 'docker',
            technology: 'Docker',
        },
        {
            id: 11,
            className: 'Kubernetes',
            technology: 'Kubernetes',
        },
        {
            id: 12,
            className: 'nosql',
            technology: 'NoSQL',
        },
        {
            id: 13,
            className: 'sql',
            technology: 'SQL',
        },
        {
            id: 14,
            className: 'paystack',
            technology: 'PayStack',
        },
        {
            id: 14,
            className: 'twilio',
            technology: 'Twilio',
        }
    ];
  
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Service Details"
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Service Details" 
            />
            <ServiceDetailsContent 
                technologies={technologies} 
                subtitle={"Website and Mobile Application Devlopment"}
                description={"theParse was started with the primary goal of playing an active key role in the digital future. Our measurable transformative purpose is to change, shape, invent the business of tomorrow through technology. "}
                nextsteps={"Our team of experts have years of experience building great software, we use our resources and capabilities to build software that gives your business a competitive edge, TODAY! Our broad skillset gives us the agility required to develop the software your business needs, when it needs it."}
                email={"info@theparse.co.za"}
            />
            {/* <RelatedServices /> */}
            <Footer />
        </Layout>
    );
}

export default Details